import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { respondTo, useBorderRadius } from '../../utils'
import Fuse from 'fuse.js'
import { createClient } from 'contentful'
import { debounce } from '../../utils'

import SearchInput from './SearchInput'
import SearchResults from './SearchResults'
import { Z_STREAM_ERROR } from 'zlib'

const Wrapper = styled.section`
  padding: 0px;
  width: 100%;
  border-radius: ${useBorderRadius('default')};
  background-color: #e9e7e1;
  overflow: visible;

  ${respondTo.small`
    padding: 40px;
  `}
`

const Title = styled.header`
  display: none;
  font: 1.5rem/1.5 neo-sans, sans-serif;
  color: ${({ theme }) => theme.darkGray};

  ${respondTo.small`
    display: block;
    margin-bottom: 1rem;
  `}
`
const Inner = styled.div`
  position: relative;
  z-index: 1;
  padding: 0px;
`

const Error = styled.pre`
  color: red;
  display: block;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-line;
`
const Search = props => {
  // load data on client mount
  const [loading, setLoading] = useState(false)
  const [allTestItems, setAllTestItems] = useState([])
  const [error, setError] = useState(null)

  const cfOptions = {
    space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
    environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
    accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
    host: process.env.GATSBY_CONTENTFUL_HOST,
  }
  if (
    !cfOptions.space ||
    !cfOptions.space === '' ||
    !cfOptions.accessToken ||
    !cfOptions.accessToken === ''
  ) {
    console.error('Contentful Options are bad. Fix pls')
  }

  // Load in data
  useEffect(() => {
    // Only run effect in browser
    if (!window) {
      return
    }

    const store = window.localStorage
    const client = createClient(cfOptions)

    /**
     * Fetch inital data. Gets the total number of items and the date of the last updated item.
     */
    getInitialData(client).then(res => {
      const caching = JSON.parse(store.getItem('DZ_caching')) || {
        updateTime: null,
        total: null,
      }
      let updateTime = res.items[0].sys.updatedAt
      if (updateTime === caching.updateTime && res.total === caching.total) {
        setAllTestItems(JSON.parse(store.getItem('DZ_searchData')))
      } else {
        store.setItem(
          'DZ_caching',
          JSON.stringify({ updateTime: updateTime, total: res.total })
        )
        setLoading(true)
        fetchData(client, res.total)
          .then(res => {
            store.setItem('DZ_searchData', JSON.stringify(res))
            setAllTestItems(res)
          })
          .catch(e => {
            store.clear()
            setAllTestItems([])
            console.log(e)
            setError(
              'Er ging iets fout met het binnehalen van de gegevens.\nHerlaad de pagina om opnieuw te proberen.\nBlijft het mislukken? Neem dan even contact op met de beheerder van de site.'
            )
          })
          .finally(() => setLoading(false))
      }
    })
  }, [])

  function fetchData(client, total) {
    const QUERYLIMIT = 200
    return new Promise((resolve, reject) => {
      // Create pagination values array
      let skips = Array.from(
        { length: Math.ceil(total / QUERYLIMIT) },
        (_, i) => i * QUERYLIMIT
      )

      //Create promises for pagination over items
      const promises = skips.map(skip => {
        return new Promise((resolve, reject) => {
          getEntries(client, skip, QUERYLIMIT)
            .then(res => resolve(res))
            .catch(e => reject(console.error(e)))
        })
      })

      /**
       * Promise.all() resolves when all passed promised have resolved, rejects when any one rejects
       * Result is array of values returned by the individual promises. In this case, a 2d array of fields.
       */
      Promise.all(promises)
        .then(res => {
          resolve(res.flat())
        })
        .catch(e => reject(e))
    })
  }

  function getInitialData(client) {
    return new Promise((resolve, reject) => {
      client
        .getEntries({
          content_type: 'testItem',
          order: '-sys.updatedAt',
          limit: 1,
        })
        .then(res => resolve(res))
        .catch(e => reject(e))
    })
  }

  function getEntries(client, skip, limit) {
    return new Promise((resolve, reject) => {
      client
        .getEntries({
          content_type: 'testItem',
          select:
            'sys.id,fields.titel,fields.synoniemen,fields.aanvraagcode,fields.indicatie',
          order: 'fields.titel',
          limit: limit,
          skip: skip,
        })
        .then(res =>
          resolve(res.items.map(item => ({ id: item.sys.id, ...item.fields })))
        )
        .catch(e => reject(e))
    })
  }

  // set fuse
  const [fuse, setFuse] = useState(null)
  const fuseOptions = {
    threshold: 0.3,
    distance: 256,
    keys: [
      {
        name: 'titel',
        weight: 0.5,
      },
      {
        name: 'synoniemen',
        weight: 0.25,
      },
      {
        name: 'aanvraagcode',
        weight: 0.125,
      },
      {
        name: 'indicatie',
        weight: 0.125,
      },
    ],
  }
  // Create new fuse instance when data is present
  useEffect(() => {
    allTestItems.length > 0
      ? setFuse(new Fuse(allTestItems, fuseOptions))
      : null
    return () => {
      setFuse(null)
    }
  }, [allTestItems])

  //do fuse query
  const [results, setResults] = useState([])
  const [inputActive, setInputActive] = useState(false)
  const doQuery = debounce(input => {
    let queryResult
    if (input && fuse) {
      setInputActive(true)
      queryResult = fuse.search(input)
    } else {
      setInputActive(false)
      queryResult = []
    }
    setResults(queryResult)
  }, 300)

  return (
    <Wrapper {...props}>
      <Title>Zoeken</Title>
      <Inner active={inputActive}>
        <SearchInput queryChange={doQuery} flatten={inputActive} />
        <SearchResults
          results={results.slice(0, 10)}
          inputActive={inputActive}
        />
        {loading && (
          // TODO: [LD-1] Create loading spinner (?)
          <p>zoekresultaten voorbereiden...</p>
        )}
        {error && <Error>{error}</Error>}
      </Inner>
    </Wrapper>
  )
}

export default Search
