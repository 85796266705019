import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useShadow, useBorderRadius, linkToId } from '../../utils'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 1rem);
  width: 100%;
  padding: 2rem 4rem;
  z-index: ${({ theme }) => theme.layers[1]};
  font-size: 1.25rem;

  background: ${({ theme }) => theme.white};
  box-shadow: ${useShadow('small')};
  border-radius: ${useBorderRadius('small')};
`

const List = styled.ul``

const Item = styled.li`
  display: block;
  padding: 0.75rem 0;
  position: relative;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.magenta};
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.gray['200']};
  }
`

const _Link = styled(Link)`
  display: flex;
  justify-content: space-between;

  ::after {
    content: '→';
    margin-left: 1rem;
    font-size: 1.5rem;
    align-self: center;
    line-height: 1;
  }
`

const NoResults = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
`

const SearchResults = ({ results, inputActive }) => {
  const withResults = (
    <List>
      {results.map((res, index) => (
        <Item key={`resultsItem__${index}`}>
          <_Link to={linkToId(res.id)}>
            <b>{res.titel}</b>
          </_Link>
        </Item>
      ))}
    </List>
  )

  const noResults = <NoResults>Geen resultaten gevonden</NoResults>

  if (inputActive) {
    if (!results.length) {
      return <Wrapper>{noResults}</Wrapper>
    } else {
      return <Wrapper>{withResults}</Wrapper>
    }
  } else return null
}

export default SearchResults
