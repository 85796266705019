import React from 'react'
import styled from 'styled-components'
import { respondTo, useShadow, useBorderRadius } from '../../utils'
import { SearchIcon } from '../icons'

const Input = styled.input.attrs(props => ({
  borderRadius: props.flatten ? '20px 20px 0px 0px' : '20px 20px 0px 20px',
}))`
  padding: 20px 20px 20px 3rem;
  width: 100%;

  font: 1.25rem/1 sans-serif;
  border-radius: ${useBorderRadius('small')};

  ::placeholder {
    color: ${({ theme }) => theme.gray};
  }

  :focus {
    outline: 0;
  }

  ${respondTo.small`
    box-shadow: none;
  `}
`

const SearchInputWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.gray};
  &:focus-within,
  &:focus {
    .icon--search {
      color: ${({ theme }) => theme.magenta};
    }
  }
  .icon--search {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    width: 2rem;
    height: 2rem;
    transform: translateY(-50%);
    transition: color 240ms ease-in-out;
  }
`

const SearchInput = ({
  queryChange,
  flatten,
  onBlurFunction,
  onFocusFunction,
}) => {
  return (
    <SearchInputWrapper>
      <Input
        placeholder="Zoek op test, indicatie of code"
        type="text"
        onChange={event => queryChange(event.target.value)}
        flatten={flatten}
        onBlur={onBlurFunction}
        onFocus={onFocusFunction}
      />
      <SearchIcon className="icon icon--search" />
    </SearchInputWrapper>
  )
}

export default SearchInput
