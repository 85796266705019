import React from 'react'
import { graphql } from 'gatsby'
import DebugOptions, { DebugButton } from '../utils/debugOptions'
import { linkToId } from '../utils'
import Layout from '../layouts'
import { BrowserView, MobileView, isMobile, isIE } from 'react-device-detect'
//components
import styled from 'styled-components'
import PageSection from '../components/PageSection'
import Search from '../components/search'

//styled
import {
  Wrapper,
  FirstLetter,
  AZBar,
  OverviewSection,
  TestItemLink,
  DZButton,
} from '../styledComponents'

const BackToTopButton = styled(DZButton)`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
`

const MobileBackToTopButton = styled(DZButton)`
  position: fixed;
  right: 1rem;
  bottom: 6rem;
`

const RootIndex = ({ data }) => {
  const { allTests } = data
  const alphabetDict = allTests.nodes.reduce((acc, cv) => {
    let firstLetter = cv.titel[0].toLocaleUpperCase()
    if (!isNaN(firstLetter)) firstLetter = '0-9'
    if (acc[firstLetter]) {
      acc[firstLetter].push(cv)
    } else {
      acc[firstLetter] = [cv]
    }
    return acc
  }, {})

  const doScroll = (node = null) => {
    let top = 0
    if (node) {
      const bounding = node.getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const offset = !isMobile ? 110 : 16
      top = scrollTop + bounding.top - offset
    }
    if (isIE) {
      window.scrollTo(0, top)
    } else {
      window.scrollTo({
        left: 0,
        top: top,
      })
    }
  }
  return (
    <Layout pageTitle="Index">
      <Wrapper>
        <MobileView renderWithFragment>
          <MobileBackToTopButton clickFunction={() => doScroll()}>
            &uarr;
          </MobileBackToTopButton>
        </MobileView>
        <BrowserView renderWithFragment>
          <BackToTopButton clickFunction={() => doScroll()}>
            &uarr;
          </BackToTopButton>
        </BrowserView>

        {process.env.NODE_ENV === 'development' && (
          <DebugOptions>
            <DebugButton onClick={() => window.localStorage.clear()}>
              Clear LS
            </DebugButton>
          </DebugOptions>
        )}
        <PageSection flex justify="center" hero color="lightBeige" layer={2}>
          {/* BEGIN SEARCH */}
          <Search />
          {/* END SEARCH */}
        </PageSection>
        <AZBar>
          {Object.keys(alphabetDict).map((firstLetter, index) => (
            <li
              tabIndex="0"
              key={index}
              onClick={() =>
                doScroll(document.getElementById(`overview__${firstLetter}`))
              }
            >
              {firstLetter}
            </li>
          ))}
        </AZBar>
        <PageSection>
          {Object.keys(alphabetDict).map(firstLetter => (
            <OverviewSection
              key={`overview__${firstLetter}`}
              id={`overview__${firstLetter}`}
            >
              <FirstLetter>{firstLetter}</FirstLetter>
              {alphabetDict[firstLetter].map((testItem, index) => (
                <TestItemLink
                  key={`item__${index}`}
                  to={linkToId(testItem.contentful_id)}
                >
                  {testItem.titel}
                </TestItemLink>
              ))}
            </OverviewSection>
          ))}
        </PageSection>
        <MobileView style={{ marginBottom: '72px' }}></MobileView>
      </Wrapper>
    </Layout>
  )
}
export default RootIndex

export const pageQuery = graphql`
  {
    allAfdeling: allContentfulAfdeling {
      nodes {
        afdeling
      }
    }

    allTests: allContentfulTestItem(sort: { fields: titel }) {
      nodes {
        contentful_id
        titel
      }
    }
  }
`
