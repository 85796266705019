import React, {useState} from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  background: #613F75CC;
  will-change: transform;
  transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: ${props => props.expanded
    ? 'translateY(0)'
    : 'translateY(calc(-100% + 3.5rem))'
  };
  z-index: 101;
`

const DebugButton = styled.button`
  background: #B7A7C0CC;
  padding: .25rem .5rem;
  font: normal normal bold 1rem/1 sans-serif;
`

const ExpandButton = styled(DebugButton)`
  width: 100%;
  margin-top: 1rem;
`

const DebugOptions = ({children}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Wrapper expanded={expanded}>
      {children}
      <ExpandButton onClick={ () => setExpanded(!expanded)}>{!expanded ? '+' : '-' }</ExpandButton>
    </Wrapper>
  )
}

export { DebugButton };
export default DebugOptions;